import { Button, Col, message, Modal, Progress, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import { IPrintLabelsProps } from './IPrintLabelsProps';
import './PrintLabels.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LabelModel } from '../../../../Models/Warehouse';
import { updateBill } from '../../../../Hooks/SuperAdmin/useEditBill';
import { Bill } from '../../../../Models/Bill';
import { API } from '../../../../API/axios';
var Barcode = require('react-barcode');

export interface printModel {
  orderId: string,
  dispatch: string,
  store: string,
  contactPhone: string,
  addressee: string,
  deliveryAddress: string,
  neighborhood: string,
  destinationCity: string,
  cellPhone: string,
  note: string,
  productsToDeliver: [
    string
  ],
  charge: number,
  additionalObservations: string,
  carrier: number
}

const PrintLabels = (props: IPrintLabelsProps) => {

  const [arrayLabels, setArrayLabels] = useState<Array<LabelModel>>(props.labelsData);
  const [perntage, setPercentage] = useState<number>(0);
  const [loading, setLoading] = useState<'Loading' | 'Generating' | ''>('');

  useEffect(() => {
    printGuias();
  }, [arrayLabels]);


  const updatePrintedLabelBills = () => {
    let arrayToUpdate: updateBill[] = arrayLabels.map((x: LabelModel) => {
      let item = props.billsArrayList.find((item: Bill) => item.id === x.billId);
      let estado: any = 'Impreso-R';
      if ((props.selctedCompanyStransport === 'Envia' || props.selctedCompanyStransport === 'Laar Courier') && props.printMode === 'Rotulos') {
        estado = 'Impreso-R';
      }
      else {
        estado = 'Despachado'
      }
      let el: updateBill = {
        id: x.billId,
        status: estado,
        guia: x.guia,
        delivery_value: item?.delivery_value,
        payment_home: item?.payment_home,
        collect_value: item?.collect_value,
        authorized: item?.authorized,
        store_ClientId: item?.store_ClientId,
        storeId: item?.storeId,
        origin_city: item?.origin_city,
        anulation_comment_id: item?.anulation_comment_id,
        company_TransportId: item?.company_TransportId,
      }
      return el;
    })
    API.put(`/Bills`, arrayToUpdate).then(res => {
      console.log(res);
      setLoading('');
    })
      .catch(err => console.error(err))
  }

  const imprimir = () => {
    setLoading('Loading');
    if ((props.selctedCompanyStransport === 'Envia' || props.selctedCompanyStransport === 'Laar Courier') && props.printMode !== 'Rotulos') {
      printenviaShippingGide();
    }
    else {
      updatePrintedLabelBills();
      printDiv();
    }
  }

  const printenviaShippingGide = () => {
    let arrayIds = arrayLabels.map(x => {
      return x.billId;
    })
    API.get(`/Bills/DownloadBillsShippingGuideReport/${arrayIds}`, {
      responseType: 'blob'
    }).then((res: any) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" })
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
      updatePrintedLabelBills();
    })
      .catch(res => {
        message.error('Hubo un error al intentar generar el reporte. Todas las facturas deben ser de la empresa transportadora Envia y tener un número de guía', 8);
        setLoading('');
      })
  }

  const printGuias = () => {
    let arrayPrint: any = [];
    let datos: any = {};
    setLoading('Loading');
    datos.report = props.selctedCompanyStransport === 'Envia' ? "GuiaEnvia" : "GuiaCrecel";
    if (props.selctedCompanyStransport === 'Envia') {
      arrayLabels.map(x => {
        let el = {
          "FechaDespacho": x.date ? x.date : "-",
          "TiendaDespacha": x.store ? x.store : "-",
          "TelefonoContacto": x.contactPhone ? x.contactPhone : "-",
          "Destinatario": x.addressee ? x.addressee : "-",
          "DireccionEntrega": x.delivery_address?.replace(/\s+/g, ' ').trim() ? x.delivery_address.replace(/\s+/g, ' ').trim() : "-",
          "Barrio": x.neighborhood ? x.neighborhood : "-",
          "Ciudad": x.city ? x.city : "-",
          "Celular": x.celphone ? x.celphone : "-",
          "Indicaciones": x.indications ? x.indications : "-",
          "ProductosEntregar": x.products ? x.products : "-",
          "ValorCobrar": x.collect_value.replace("$", "").replace(",", "").replace(" ", ""),
          "ObervacionesAdicionales": x.observations ? x.observations : "-",
          "Guia": x.guia ? x.guia.toString() : "-",
          "warning": "peligro.png",
          "TextWarning": " Apreciado cliente si ud. encuentra roto este sello o alguna anomalía en la unidad de empaque, asegurese de registrar la novedad al lado de su firma en la constancia de entrega."
        }
        arrayPrint.push(el);
      })
    }
    else {
      arrayLabels.map(x => {
        let el = {
          "FechaDespacho": x.date ? x.date : "-",
          "TiendaDespacha": x.store ? x.store : "-",
          "TelefonoContacto": x.contactPhone ? x.contactPhone : "-",
          "Destinatario": x.addressee ? x.addressee : "-",
          "DireccionEntrega": `${x.delivery_address?.replace(/\s+/g, ' ').trim() ? x.delivery_address.replace(/\s+/g, ' ').trim() : "-"} Barrio:${x.neighborhood ? x.neighborhood : "-"}`,
          "Ciudad": x.city ? x.city : "-",
          "Celular": x.celphone ? x.celphone : "-",
          "Indicaciones": x.indications ? x.indications : "-",
          "ProductosEntregar": x.products ? x.products : "-",
          "ValorCobrar": x.collect_value.replace("$", "").replace(",", "").replace(" ", ""),
          "ObervacionesAdicionales": x.observations,
          "Guia": x.guia ? x.guia.toString() : "-",
          "TextWarning": " Apreciado cliente si ud. encuentra roto este sello o alguna anomalía en la unidad de empaque, asegurese de registrar la novedad al lado de su firma en la constancia de entrega."
        }
        arrayPrint.push(el);
      });
    }

    datos.data = arrayPrint
    /*billsAPI.PrintGuias(datos).then((res: any) => {
      updatePrintedLabelBills();
      setLoading('');
      const pdfBlob = new Blob([res], { type: "application/pdf" })
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
      setLoading('');
    });*/
  }

  function printDiv() {
    const pdf: any = new jsPDF("p", "mm", "a4");
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var quantity = 3
    if (props.selctedCompanyStransport === 'Envia') {
      quantity = 2
    }
    var position = 0;
    arrayLabels.map((x: LabelModel, i: number) => {
      let input: any = document.getElementById(`divToPrint-${i}`);
      html2canvas(input)
        .then((canvas: any) => {
          let imgData: any = canvas.toDataURL('image/png', 1.0);
          if (props.selctedCompanyStransport === 'Envia') {
            // IMPRESIÓN DE 2 COPIAS PARA ENVIA
            pdf.addImage(imgData, 'JPEG', 0, position, width, (height / quantity), '', 'FAST');
            position = position + (height / quantity);
            pdf.addImage(imgData, 'JPEG', 0, position, width, (height / quantity), '', 'FAST');
            position = position + (height / quantity);
          }
          else {
            // IMPRESIÓN DE 3 COPIAS PARA LOS DEMÁS
            pdf.addImage(imgData, 'JPEG', 0, position, width, (height / quantity), '', 'FAST');
            position = position + (height / quantity);
            pdf.addImage(imgData, 'JPEG', 0, position, width, (height / quantity), '', 'FAST');
            position = position + (height / quantity);
            pdf.addImage(imgData, 'JPEG', 0, position, width, (height / quantity), '', 'FAST');
            position = position + (height / quantity);
          }
          if (arrayLabels.length === i + 1) {
            let string: any = pdf.output('bloburl')
            window.open(string, '_blank');
            setPercentage(100)
          }
          else {
            setLoading('Generating');
            setPercentage((i * 100) / arrayLabels.length)
            //if ((i + 1) % quantity === 0) {
            position = 0;
            pdf.addPage();
            //}
          }
        });
    });
  }

  const closeModal = (status: string) => {
    props.getPendingBillsByStatus(status);
    props.setModalVisible(false);
    window.location.reload();
  }

  window.onafterprint = function () {
    window.location.reload();
  }
  return (
    <div className={`printLabels`}>
      <Modal
        width={1000}
        visible={props.ModalVisible}
        onOk={() => closeModal(props.printMode === "Rotulos" ? "Pendiente" : "Impreso-R")}
        onCancel={() => closeModal(props.printMode === "Rotulos" ? "Pendiente" : "Impreso-R")}
        footer=
        {[
          <Button key="submit" type="primary" onClick={() => closeModal(props.printMode === "Rotulos" ? "Pendiente" : "Impreso-R")}> Cerrar </Button >
        ]}
      >
        <Button onClick={() => imprimir()}>Imprimir</Button>
        {loading == 'Generating' &&
          <Progress
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            percent={perntage}
          />
        }
        {props.printMode === 'Rotulos' ?
          <div id="element-to-print">
            {arrayLabels.map((x: LabelModel, i: number) =>
              <div id={`divToPrint-${i}`}>
                <Row className={`encabezado`}>
                  <Col span={6} className={'padding'}>
                    <b>Fecha de despacho:</b><br />
                    <div className={'dataFontSize'}>
                      {x.date}
                    </div>
                  </Col>
                  <Col span={6} className={'padding'}>
                    <>{console.log(x)}</>
                    <b>Tienda que despacha:</b><br />
                    <div className={'dataFontSize'}>
                      {x.store}
                    </div>
                  </Col>
                  <Col span={6} className={'padding'}>
                    <>{console.log(x)}</>
                    <b>Identificación:</b><br />
                    <div className={'dataFontSize'}>
                      {x.storeIdentification}
                    </div>
                  </Col>
                  <Col span={6} className={'padding'}>
                    <b>Teléfono contacto:</b><br />
                    <div className={'dataFontSize'}>
                      {x.contactPhone}
                    </div>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Destinatario:</b> <br />
                    <div className={'dataFontSize'}>
                      {x.addressee}
                    </div>
                  </Col>
                  <Col span={4} className={`borderRight padding`}>
                    <b>Identificación:</b> <br />
                    <div className={'dataFontSize'}>
                      {x.clientIdentificacion}
                    </div>
                  </Col>
                  <Col span={10} className={`borderRight padding`}>
                    <b>Dirección de entrega:</b><br />
                    <div className={'dataFontSize'}>
                      {x.delivery_address}
                    </div>
                  </Col>
                  <Col span={4} className={'borderRight padding'}>
                    <b>Barrio:</b><br />
                    <div className={'dataFontSize'}>
                      {x.neighborhood}
                    </div>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Ciudad destino:</b> <br />
                    <div className={'dataFontSize'}>
                      {x.city}
                    </div>
                  </Col>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Celular / Tel:</b><br />
                    <div className={'dataFontSize'}>
                      {x.celphone}
                    </div>
                  </Col>
                  <Col span={12} className={`borderRight padding`}>
                    <b>Indicaciones:</b><br />
                    <div className={'dataFontSize'}>
                      {x.indications}
                    </div>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={18} className={`borderRight padding`}>
                    <b>Producto (s) a entregar:</b> <br />
                    <div className={'dataFontSize'}>
                      {x.products}
                    </div>
                  </Col>
                  <Col span={6} className={'padding'}>
                    <b>Valor a Cobrar:</b> <br />
                    <span style={{ fontSize: 30, fontWeight: 'bold', marginLeft: 20 }}>
                      {x.payment_home ? x.collect_value : 0}
                    </span>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={24} className={'padding'}>
                    <b>Observaciones adicionales:</b>
                    <div className={'dataFontSize'}>
                      {x.observations.split("/").length > 0 &&
                        <u>{x.observations.split("/")[1]}</u>
                      }
                      {x.deliveryInWarehouse && " / Recoge en bodega CRECEL"}
                    </div>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={12} className={`borderRight padding`} style={{ textAlign: 'center' }}>
                    <Barcode
                      value={x.guia}
                      height={50}
                      textPosition="top"
                    />
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{x.payment_home ? "- PARA COBRO -" : null}</div>
                  </Col>
                  <Col span={12} className={'padding'}>
                    <Row>
                      <Col span={6} className={'padding'} style={{ textAlign: 'center' }}>
                        <img className={'imgWarning'} src={'https://drophacking.blob.core.windows.net/crecel-app-assets/peligro.png'} />
                      </Col>
                      <Col span={18} className={'padding'} style={{ fontSize: 15 }}>
                        Apreciado cliente si ud. encuentra roto este sello o alguna anomalía en la unidad de empaque, asegurese de registrar la novedad al lado de su firma en la constancia de entrega.
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className={'dashed'} style={{ marginBottom: 20 }}></hr>
                <div className="pagebreak"> </div>
              </div>
            )}
          </div>
          :
          <div id="element-to-print">
            {arrayLabels.map((x: LabelModel, i: number) =>
              <div id={`divToPrint-${i}`}>
                <Row className={`encabezado`}>
                  <Col span={4} className={`borderRight padding`}>
                    <b>Fecha:</b><br />
                    {x.date}
                  </Col>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Tienda que despacha:</b><br />
                    {x.store}
                  </Col>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Teléfono contacto:</b><br />
                    {x.contactPhone}
                  </Col>
                  <Col span={7}>
                    <Barcode
                      value={x.guia}
                      height={30}
                      textPosition="top"
                    />
                  </Col>
                  <Col span={1}></Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Cliente:</b> <br />
                    {x.addressee}
                  </Col>
                  <Col span={12} className={`borderRight padding`}>
                    <b>Dirección:</b><br />
                    {x.delivery_address}
                  </Col>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Barrio:</b><br />
                    {x.neighborhood}
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Ciudad destino:</b> <br />
                    {x.city}
                  </Col>
                  <Col span={6} className={`borderRight padding`}>
                    <b>Celular / Tel:</b><br />
                    {x.celphone}
                  </Col>
                  <Col span={12} className={`borderRight padding`}>
                    <b>Observaciones:</b><br />
                    {x.observations}
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={18} className={`borderRight padding`}>
                    <b>Producto (s) a entregar:</b> <br />
                    {x.products}
                  </Col>
                  <Col span={6} className={'padding'}>
                    <b>Valor a Cobrar:</b> <br />
                    <span style={{ fontSize: 30, fontWeight: 'bold', marginLeft: 20 }}>
                      {x.collect_value}
                    </span>
                  </Col>
                </Row>
                <Row className={'borderTop'}>
                  <Col span={24} className={`padding`}>
                    <b>Observaciones de entrega:</b><br /><br />
                    <div className={'dashed'}>&nbsp;</div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        }
      </Modal>
    </div>

  );
};

export default PrintLabels;
