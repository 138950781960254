import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarCircleOutlined,
  DropboxOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  SafetyOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Result,
  Row,
  Spin,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { API } from "../../API/axios";
import { PageContext } from "../../Hooks/ContextProvider";
import { updateBill } from "../../Hooks/SuperAdmin/useEditBill";
import { BillData, palomaBillGet } from "../../Models/Bill";
import { city } from "../../Models/city.model";
import { BillProducts, product } from "../../Models/Products";
import ModalBillNews from "../ModalBillNews/ModalBillNews";
import ModalCreateDeliveryNews from "../ModalCreateDeliveryNews/ModalCreateDeliveryNews";
import ModalUpdateBillData from "../ModalUpdateBillData/ModalUpdateBillData";

import { IModalDetalleGuiaProps } from "./IModalDetalleGuiaProps";
import "./ModalDetalleGuia.css";
import { notification } from 'antd';
import { ModalBillMovements } from "../ModalBillMovements/ModalBillMovements";
var Barcode = require("react-barcode");

const ModalDetalleGuia = (props: IModalDetalleGuiaProps) => {
  const [billData, setbillData] = useState<BillData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [nocontent, setNocontent] = useState<boolean>(false);
  const [store, setStore] = useState<any>({});
  const [city_, setCity] = useState<string>("");
  const [products, setProducts] = useState<string>("");
  const [dataEnvia, setDataEnvia]: any = useState();
  const [palomaBillData, setPalomaBillData] = useState<palomaBillGet>();
  const [modalVisibleUpdateBillData, setModalVisibleUpdateBillData] =
    useState<boolean>(false);

  const { userData } = useContext(PageContext);

  useEffect(() => {
    GetDeliveryData(props.guia);
    getPalomaBills(props.guia);
    console.log(props);

  }, [props]);

  const GetDeliveryData = async (guia: string) => {
    let res: any = "";
    if (guia != "") {
      res = await API.get<BillData | "">(`/Bills/GetBillByGuia/${guia}`);
      res = res.data;
    } else {
      res = props.BillData;
    }
    if (res !== "") {
      if (
        res.storeId.toString() == userData?.storeID ||
        userData?.roles.includes("Admin") ||
        userData?.roles.includes("SuperAdmin") ||
        userData?.roles.includes("Bodega")
      ) {
        if (res.observaciones_Envio?.length > 0) {
          res.observaciones_Envio = res.observaciones_Envio.split("/")[1];
        }

        setbillData(res);
        getStoreData(res.storeId);
        getCity(res?.cityId ?? res.storeClient.cityId);
        getProducts(res.bill_Products);
        if (res.company_TransportId == 1) {
          getEnviaData(guia);
        }
      } else {
        setNocontent(true);
      }
    }
  };

  const getEnviaData = async (guia: string) => {
    let resp = await API.get(`/Bills/GetStatusDeliveryEnvia?guia=${guia}`);
    setDataEnvia(resp.data);
  };

  const getStoreData = async (storeId: number) => {
    let resp = await API.get(`/Stores/${storeId}`);
    setStore(resp.data);
  };

  const getCity = async (id: number) => {
    const res = await API.get<city[]>("/Cities");
    let city = res.data.filter((x) => x.id === id)[0];
    setCity(`${city.name} - ${city.provinceName}`);
  };

  const getProducts = async (data: BillProducts[]) => {
    let res = await API.get<product[]>("/Products");
    let arrayProducts: string[] = data.map((prod: BillProducts) => {
      return ` ${prod.quantity}x ${res.data.filter((p: product) => p.id === prod.productId)[0].name
        }`;
    });

    setProducts(arrayProducts.join());
  };

  const anularGuia = () => {
    if (billData) {
      billData.status = "Pendiente";
      let array: updateBill[] = [];
      let el: any = billData;
      array.push(el);

      if (billData.company_TransportId !== 2) {
        API.put(`/Bills`, array).then((res) => {
          let el = {
            billId: billData.id,
            anulationCommentId: 1,
            observaciones_Cancelacion: "Solicitud de la tienda",
          };
          API.post(`/Bills/CancelBill`, el)
            .then((res) => {
              message.success("La guia fue Anulada");
            })
            .catch((res) => {
              message.error("Esta guia no puede ser anulada");
            });
        });
      }
    }
  };

  const SolicitarAnular = () => {
    if (billData) {
      billData.status = "Anulación Solicitada";

      if (billData.company_TransportId === 2) {
        let payload = palomaBillData;
        let el = {
          ...payload,
          statusId: 11,
          status: "Solicitud Anulación",
        };
        axios.post(
          `https://ws-prod.palomamensajeria.com/api/Bill/UpdateBillHeader`,
          el
        );
      }
    }
    let array: updateBill[] = [];
    let el: any = billData;
    array.push(el);
    API.put(`/Bills`, array)
      .then((res) => {
        message.success("La solicitud de anulación fue enviada");
      })
      .catch((res) => {
        message.error("Esta guia no puede ser anulada");
      });
  };

  const getPalomaBills = async (guia: string) => {
    let payload = {
      guia: guia,
    };

    let bills = await axios.post<palomaBillGet>(
      `https://ws-prod.palomamensajeria.com/api/Bill/GetBillById`,
      payload
    );
    setPalomaBillData(bills.data);
  };

  const openModalEditBillData = () => {
    setModalVisibleUpdateBillData(true);
  };

  const LiquidarGuia = async (guia: string) => {
    setLoading(true);
    let data = [guia]
    let resp = await API.post(`/StoreWallet/SettleInvoice`, data);
    if (resp.data) {
      notification.success({ message: "La guia fue liquidada" });
    }
    else {
      notification.error({ message: 'Error al liquidar la guia' });
    }
    setLoading(false);
  }

  return (
    <div className={`modalDetalleGuia`}>
      <Modal
        open={props.visible}
        title={
          <Row>
            <Col span={15}>
              {`GUIA: ${props.guia}`}
              <br />
              {`MENSAJERO: ${billData?.courier ? billData.courier : "Sin asignar"
                }`}
              <br />
              {billData?.company_TransportId == 1 &&
                dataEnvia &&
                `ESTADO ENVIA: ${dataEnvia.estado}`}
            </Col>
            <Col span={7}>
              {billData?.status === "Pendiente" && (
                <Button type="text">
                  <CloseCircleOutlined /> Pendiente
                </Button>
              )}
              {(billData?.status === "Despachado" ||
                billData?.status === "Impreso-R" ||
                billData?.status === "Impreso-G") && (
                  <Button type="text">
                    <DropboxOutlined /> Despachado
                  </Button>
                )}
              {(billData?.status === "Impreso-R" ||
                billData?.status === "Impreso-G") && (
                  <Button type="text">
                    <DropboxOutlined /> Empacado
                  </Button>
                )}
              {billData?.status === "Anulado" && (
                <Button type="text">
                  <CloseCircleOutlined style={{ color: "red" }} /> Anulado
                </Button>
              )}
              {billData?.status === "Anulación Solicitada" && (
                <Button type="text">
                  <CloseCircleOutlined style={{ color: "red" }} /> Pendiente por
                  anular
                </Button>
              )}
              {billData?.status === "Novedad" && (
                <Button type="text">
                  <IssuesCloseOutlined style={{ color: "orange" }} /> Novedada
                </Button>
              )}
              {billData?.status === "Devuelto" && (
                <Button type="text">
                  <UndoOutlined style={{ color: "red" }} /> Devuelto
                </Button>
              )}
              {billData?.status === "Entregado" && (
                <Button type="text">
                  <CheckCircleOutlined style={{ color: "green" }} /> Entregado
                </Button>
              )}
              {billData?.status === "Pagado" && (
                <Button type="text">
                  <DollarCircleOutlined style={{ color: "green" }} /> Pagado
                </Button>
              )}
              {billData?.status === "Adelantado" && (
                <Button type="text">
                  <SafetyOutlined style={{ color: "green" }} /> Adelantado
                </Button>
              )}
              {billData?.status === "Solucionada" && (
                <Button type="text">
                  <SafetyOutlined style={{ color: "green" }} /> Solucionado
                </Button>
              )}
            </Col>
            <Col span={1}><ModalBillMovements BillId={billData?.id} /></Col>

          </Row>
        }
        width={1000}
        onOk={() => props.setVisible(false)}
        onCancel={() => props.setVisible(false)}
        footer={[
          <Button key="back" onClick={() => props.setVisible(false)}>
            Cerrar
          </Button>,
        ]}
      >
        <Spin tip="Cargando..." spinning={loading}>
          {nocontent ? (
            <Result
              status="warning"
              title={`No se encontró información para la guia ${props.guia}`}
            />
          ) : (
            <div>
              <Row className={`encabezado`}>
                <Col span={4} className={`borderRight padding`}>
                  <img
                    src={
                      "https://drophacking.blob.core.windows.net/crecel-app-assets/Logo.png"
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={5} className={`borderRight padding`}>
                  <b>Tienda que despacha:</b>
                  <br />
                  {store.name}
                </Col>
                <Col span={4} className={`borderRight padding`}>
                  <b>Identificación:</b>
                  <br />
                  {store.identification}
                </Col>
                <Col span={3} className={`borderRight padding`}>
                  <b>Fecha:</b>
                  <br />
                  {moment(billData?.created_at)
                    .utcOffset("GMT-05")
                    .format("YYYY-MM-DD")}
                </Col>
                <Col span={7}>
                  <Barcode
                    value={props.guia != "" ? props.guia : "123456"}
                    height={30}
                    textPosition="top"
                  />
                </Col>
                <Col span={1}></Col>
              </Row>
              <Row className={`borderTop`}>
                <Col span={4} className={`borderRight padding`}>
                  <b>Cliente:</b> <br />
                  {billData?.storeClient.name}
                </Col>
                {<Col span={4} className={`borderRight padding`}>
                  <b>Identificacion:</b> <br />
                  {billData?.storeClient.identification ? billData?.storeClient.identification : "----------------"}
                </Col>}
                <Col span={11} className={`borderRight padding`}>
                  <b>Dirección:</b>
                  <br />
                  {`${billData?.address !== null ? `${billData?.address} ${billData?.adress_indications ?? ""}` : `${billData?.storeClient.carrera} ${billData?.storeClient.adress_field_1
                    } 
                    ${billData?.storeClient.adress_field_2} ${billData?.storeClient.adress_field_3
                    } 
                    ${billData?.storeClient.adress_field_1 !== "" ? "#" : ""} 
                    ${billData?.storeClient.adress_field_4} ${billData?.storeClient.adress_field_5
                    } ${billData?.storeClient.adress_field_6}
                    ${billData?.storeClient.adress_field_7 !== "" ? "-" : ""} ${billData?.storeClient.adress_field_7
                    }
                    ${billData?.storeClient.adress_field_8} ${billData?.storeClient.adress_field_9
                    }
                    ${billData?.storeClient.adress_field_10} ${billData?.storeClient.adress_field_11
                    } 
                    ${billData?.storeClient.adress_field_12}
                    ${billData?.storeClient.adress_indications}
                    ${billData?.storeClient.building}
                    `}
                    `}
                </Col>
                <Col span={5} className={`borderRight padding`}>
                  <b>Barrio:</b>
                  <br />
                  {billData?.neighborhood ?? billData?.storeClient.neighborhood}
                </Col>
              </Row>
              <Row className={`borderTop`}>
                <Col span={6} className={`borderRight padding`}>
                  <b>Ciudad destino:</b> <br />
                  {city_}
                </Col>
                <Col span={6} className={`borderRight padding`}>
                  <b>Celular / Tel:</b>
                  <br />
                  {billData?.storeClient.cellphone} /{" "}
                  {billData?.storeClient.phone}
                </Col>
                <Col span={12} className={`borderRight padding`}>
                  <b>Observaciones:</b>
                  <br />
                  {billData?.observaciones_Envio}
                  <div>
                    {billData?.deliveryInWarehouse &&
                      "Recoge en bodega CRECEL"}
                  </div>
                  {billData?.observaciones_Cancelacion &&
                    `Anulada por: ${billData?.observaciones_Cancelacion}`}
                </Col>
              </Row>
              <Row className={`borderTop`}>
                <Col span={18} className={`borderRight padding`}>
                  <b>Producto (s) a entregar:</b> <br />
                  {products}
                </Col>
                <Col span={6} className={"padding"}>
                  <b>Valor a Cobrar:</b> <br />
                  <span
                    style={{ fontSize: 30, fontWeight: "bold", marginLeft: 20 }}
                  >
                    {`$ ${billData?.collect_value}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </span>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ padding: 20 }}>
                <Col span={4}>
                  {userData?.roles.includes("Admin") ||
                    userData?.roles.includes("Bodega") ||
                    userData?.roles.includes("SuperAdmin") ||
                    localStorage.userID === "774" ? (
                    <Popconfirm
                      title={`¿Estado Seguro de anular esta guia ?`}
                      onConfirm={() => anularGuia()}
                      okText={"Sí"}
                      cancelText={"No"}
                      disabled={
                        billData?.status !== "Pendiente" &&
                        billData?.status !== "Impreso-G" &&
                        billData?.status !== "Impreso-R" &&
                        billData?.status !== "Anulación Solicitada"
                      }
                    >
                      <Button
                        disabled={
                          billData?.status !== "Pendiente" &&
                          billData?.status !== "Impreso-G" &&
                          billData?.status !== "Impreso-R" &&
                          billData?.status !== "Anulación Solicitada"
                        }
                      >
                        <CloseCircleOutlined style={{ color: "red" }} /> Anular{" "}
                      </Button>
                    </Popconfirm>
                  ) : (
                    billData?.storeId == userData?.storeID && (
                      <Popconfirm
                        title={`¿Estado Seguro de anular esta guia ?`}
                        onConfirm={() => SolicitarAnular()}
                        okText={"Sí"}
                        cancelText={"No"}
                        disabled={
                          billData?.status !== "Pendiente" &&
                          billData?.status !== "Impreso-G" &&
                          billData?.status !== "Impreso-R"
                        }
                      >
                        <Button
                          disabled={
                            billData?.status !== "Pendiente" &&
                            billData?.status !== "Impreso-G" &&
                            billData?.status !== "Impreso-R"
                          }
                        >
                          <CloseCircleOutlined style={{ color: "red" }} />{" "}
                          Solicitar Anulación{" "}
                        </Button>
                      </Popconfirm>
                    )
                  )}
                </Col>
                <Col span={4}>
                  {billData && (
                    <ModalBillNews
                      guia={billData.guia}
                      company_TransportId={billData.company_TransportId}
                      billingId={billData.id}
                    />
                  )}
                </Col>
                <Col span={4}>
                  {(userData?.roles.includes("Admin") ||
                    userData?.roles.includes("SuperAdmin")) &&
                    billData && (
                      <ModalCreateDeliveryNews
                        billingId={billData?.id}
                        guia={billData?.guia}
                        billData={billData}
                      />
                    )}
                </Col>
                <Col span={4}>
                  {(userData?.roles.includes("Admin") ||
                    userData?.roles.includes("SuperAdmin") ||
                    userData?.roles.includes("Bodega")) && (
                      <Button onClick={() => openModalEditBillData()}>
                        <EditOutlined />
                        Editar Guia
                      </Button>
                    )}
                </Col>
                {billData &&
                  <>
                    {(userData?.roles.includes("SuperAdmin") && billData.status != 'Pagado') && <Col span={4}>
                      <Popconfirm onConfirm={() => LiquidarGuia(billData?.guia)} title="Liquidar esta guia?">
                        <Spin spinning={loading}>
                          <Button type="primary">
                            Liquidar Guia
                          </Button>
                        </Spin>
                      </Popconfirm>
                    </Col>}
                  </>
                }
              </Row>
              {billData?.guia && (
                <ModalUpdateBillData
                  visible={modalVisibleUpdateBillData}
                  setVisible={setModalVisibleUpdateBillData}
                  guia={billData.guia}
                  reloadData={getStoreData}
                />
              )}
            </div>
          )}
        </Spin>
      </Modal>
    </div>
  );
};

export default ModalDetalleGuia;
