import React, {
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import { UploadOutlined } from '@ant-design/icons';
import { IGenerateBillProps } from "./IGenerateBillProps";
import "./GenerateBill.css";
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  Button,
  Spin,
  message,
  InputNumber,
  Popconfirm,
  Table,
  Modal,
  notification,
  Upload,
} from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { city, country } from "../../Models/city.model";
import {
  BillProducts,
  Inventory,
  product,
  storeProduct,
} from "../../Models/Products";
import {
  cities_Company_Transports,
  company_transport,
} from "../../Models/company_Transport";

import { storeModel } from "../../Models/store";
import { StoreClient } from "../../Models/StoreClient";
import { API } from "../../API/axios";
import { PageContext } from "../../Hooks/ContextProvider";
import { Bill, BillData, ChanelPreBill, PreBill } from "../../Models/Bill";
import ModalDetalleGuia from "../ModalDetalleGuia/ModalDetalleGuia";
import TablePreBill from "../TablePreBill/TablePreBill";
import { Popup, ScrollView } from "devextreme-react";

// Images
const sobre =
  "https://drophacking.blob.core.windows.net/crecel-app-assets/sobre.png";

const { Option } = Select;

const GenerateBill = (props: IGenerateBillProps) => {
  const { userData, setSelectedComponent } = useContext(PageContext);

  const [countries, setCountries] = useState<country[]>([]);
  const [countrySelected, setCountrySelected] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [citiesList, setCitiesList] = useState<Array<city>>([]);
  const [citiesListFiltered, setCitiesListFiltered] = useState<Array<city>>([]);
  const [productsListFiltered, setProductsListFiltered] = useState<
    Array<product>
  >([]);
  const [delivery_value, setDelivery_value] = useState(0);
  const [collect_value, setCollect_value] = useState(0);
  const [payment_home, setPayment_home] = useState(true);
  const [bill_ProductsList, setBill_Products] = useState<Array<BillProducts>>(
    []
  );
  const [lastBillsByClient, setLastBillsByClient] = useState([]);
  const [companyTransportList, setCompanyTransportList] = useState<
    company_transport[]
  >([]);
  const [selectedCompanyTransport, setSelectedCompanyTransport] =
    useState<number>();
  const [payment_home_disabled, setPayment_home_disabled] =
    useState<boolean>(false);
  const [storeClientExist, setStoreClientExist] = useState<boolean>(false);
  const [storeData, setStoreData] = useState<storeModel | undefined>(undefined);
  const [selectedCityId, setSelectedCityId] = useState<number>(0);
  const [deliveryComments, setDeliveryComments] = useState<string>("");
  const [storePickup, setStorePickup] = useState<boolean>(false);
  const [products, setProducts] = useState<product[]>([]);
  const [usdProductsList, setUsdProductsList] = useState<product[]>([]);
  const [productsUsdListFiltered, setProductsUsdListFiltered] = useState<
    product[]
  >([]);
  const [usdCollectValue, setUsdCollectValue] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [storeClient, setStoreClient] = useState<StoreClient>({
    adress_field_1: "",
    adress_field_2: "",
    adress_field_3: "",
    adress_field_4: "",
    adress_field_5: "",
    adress_field_6: "",
    adress_field_7: "",
    adress_field_8: "",
    adress_field_9: "",
    adress_field_10: "",
    adress_field_11: "",
    adress_field_12: "",
    adress_indications: "",
    building: "",
    carrera: "",
    cellphone: "",
    cityId: "",
    created_at: new Date(),
    created_by: parseInt(localStorage.userID),
    email: "",
    gender: "",
    id: 0,
    identification: "",
    name: "",
    neighborhood: "",
    phone: "",
    updated_at: new Date(),
    warningComments: "",
  });

  const [currentStep, setCurrentStep] = useState<
    "Datos del Cliente" | "Localización y envío" | "Productos"
  >("Datos del Cliente");
  const [advertenciaMostrada, setAdvertenciaMostrada] =
    useState<boolean>(false);
  const [inventory, setInventory] = useState<Inventory[]>([]);
  const [billData, setbillData] = useState<BillData>();
  const [preBills, setPrebills] = useState<PreBill[]>([]);
  const [currentPreBills, setCurrentPreBills] = useState<PreBill[]>([]);

  useEffect(() => {
    if (!initialized && localStorage.storeID !== "") {
      getCountries();
      getProducts();
      setInitialized(true);
      GetCompanyTransport();
      getStoreData();
      getInventoryProducts();
      handleCountrySelect(parseInt(localStorage.country));
      GetPresales();
    }
  }, []);

  const previewModal = () => {
    let arrayProducts: string[] = bill_ProductsList.map(
      (prod: BillProducts) => {
        return ` ${prod.quantity}x ${products.filter((p: product) => p.id === prod.productId)[0].name
          }`;
      }
    );
    let stringProducts = arrayProducts.join();
    let bill: BillData = {
      id: 0,
      anulation_comment_id: 0,
      created_at: moment(new Date()).format("YYYY-MM-DD"),
      updated_at: moment(new Date()).format("YYYY-MM-DD"),
      delivery_News: [],
      storeClient: storeClient,
      envia: {
        cod_FormaPago: 4,
        mPesoVolumen_K: 1,
        cod_Cuenta: payment_home ? 518 : 517,
        nom_remitente:
          storeData !== undefined ? `CREC - ${storeData.name}` : "-",
        dir_remitente: "Carrera 50 FF # 10 sur 150",
        tel_remitente: storeData !== undefined ? storeData.cel : "-",
        ced_remitente: storeData !== undefined ? storeData.identification : "-",
        texto_Guia: `Barrio:${storeClient.neighborhood} ${stringProducts} / ${deliveryComments}`,
      },
      guia: moment(new Date()).format("YYYYMMDD"),
      delivery_value: delivery_value,
      status: "Pendiente",
      payment_home: payment_home,
      collect_value: payment_home
        ? localStorage.currency == "COP"
          ? collect_value
          : usdCollectValue
        : 0,
      authorized: true,
      store_ClientId: 0,
      storeId: parseInt(localStorage.storeID),
      origin_city: 6834,
      created_by: parseInt(localStorage.userID),
      bill_Products: bill_ProductsList,
      company_TransportId:
        selectedCompanyTransport !== undefined ? selectedCompanyTransport : 2,
      observaciones_Envio: `${stringProducts} / ${deliveryComments}`,
      deliveryInWarehouse: storePickup,
      deliveryComments: deliveryComments,
      adress_indications: deliveryComments,
      cityId: selectedCityId,
      address: `${storeClient.carrera} ${storeClient.adress_field_1}${storeClient.adress_field_2}${storeClient.adress_field_3}${storeClient.adress_field_1 !== "" ? "#" : ""} 
                    ${storeClient.adress_field_4} ${storeClient.adress_field_5} ${storeClient.adress_field_6}${storeClient.adress_field_7 !== "" ? "-" : ""} ${storeClient.adress_field_7}
                    ${storeClient.adress_field_8} ${storeClient.adress_field_9}${storeClient.adress_field_10} ${storeClient.adress_field_11} 
                    ${storeClient.adress_field_12}${storeClient.adress_indications}${storeClient.building}`
    };  
    setbillData(bill);
    setVisible(true);
  };

  const getInventoryProducts = async () => {
    let resp = await API.get<Inventory[]>(
      "/Inventory_Movement/GetInventoryByWarehouseId",
      { params: { warehouseId: 1 } }
    );
    setInventory(resp.data);
  };

  const getStoreData = async () => {
    setLoading(true);
    let resp = await API.get(`/Stores/${userData?.storeID}`);
    if (resp.status === 200) {
      setStoreData(resp.data);
      setLoading(false);
    }
  };

  const findClientByPhone = async (phone: string, type: string) => {
    if (phone !== "") {
      let params = { cellphone: phone };
      let resp = await API.get("/StoreClient", { params });
      if (resp.data != "") {
        setStoreClient(resp.data);
        if (resp.data.id) {
          GetLastBuysByClient(resp.data.id);
        }
        setStoreClientExist(true);
      }
    }
  };

  const updateStoreClientState = (value: any, field: string) => {
    let userTemp = { ...(storeClient as any) };
    userTemp[field] = value;
    setStoreClient(userTemp);
    if (field === "cityId") {
      let selectedCity = citiesList.filter((x) => x.id == value);
      setSelectedCityId(selectedCity[0].id)
      if (selectedCity.length > 0 && selectedCity[0].message) {
        Modal.warning({
          title: "!Atención!",
          content: selectedCity[0].message,
        });
      }
      let city = companyTransportList[0].cities_Company_Transports.filter(
        (x) => x.cityId == value
      );

      if (city.length > 0) {
        if (city[0].rx) {
          Modal.warning({
            title: "Esta ciudad es una reexpedición",
            content: "Su entrega puede tardar entre 8 y 15 dias.",
          });
        }
      }
      setSelectedCompanyTransport(undefined);
    }
  };

  const getCountries = async () => {
    try {
      const resp = await API.get<country[]>("/Countries");
      setCountries(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountrySelect = async (e: number) => {
    setStoreClient({
      ...storeClient,
      cityId: 0,
    });
    setSelectedCompanyTransport(undefined);
    setCountrySelected(0);
    try {
      const country = countries.find((c) => c.id === e)?.name;

      if (country === "Ecuador") {
        const productUsd = products.filter((p) => p.isActiveEcuador === true);
        setUsdProductsList(productUsd);
        setProductsUsdListFiltered(productUsd);
      }

      let countryId = parseInt(localStorage.country);

      const res = await API.get<city[]>("/Cities/CitiesByCountry", {
        params: { countryId: countryId },
      });
      res.data.map((x) => {
        x.name = `${x.name} (${x.provinceName})`;
        return x;
      });
      setCitiesList(res.data.filter((x: city) => x.isActived == true));
      setCitiesListFiltered(res.data.filter((x: city) => x.isActived == true));
      setCountrySelected(e);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    let prodFull = await API.get<product[]>("/Products");
    let prod = prodFull.data.filter((pro: product) => (pro.isActive && localStorage.currency == 'COP') || (pro.isActiveEcuador && localStorage.currency == 'USD'));
    setProducts(prod);

    let res = await API.get(
      `/StoreProducts?id=${userData?.storeID}&Currency=${localStorage.currency}`
    );
    let arrayTemp: any = [];
    if (res.data.length > 0) {
      res.data.map((x: storeProduct, i: number) => {
        let newProduct = prod.filter(
          (pro: product) => pro.id === x.productId
        )[0];
        if (newProduct) {
          const prodExist = arrayTemp
            .map((np: any) => np.id)
            .indexOf(newProduct.id);
          if (prodExist == -1) {
            arrayTemp.push(
              prod.filter((prod: product) => prod.id === x.productId)[0]
            );
            const prodExist_ = arrayTemp
              .map((np: any) => np.id)
              .indexOf(newProduct.id);
            arrayTemp[prodExist_].Store_Selling_price =
              localStorage.currency == "COP"
                ? x.selling_price
                : x.usD_selling_price;
          }
          if (res.data.length === i + 1) {
            setProducts(arrayTemp);
            setProductsListFiltered(arrayTemp);
          }
        }
      });
    } else {
      message.warning(
        "Tú tienda aún no tiene productos disponibles para vender"
      );
    }
  };

  // TODO:
  const GetLastBuysByClient = async (id: number) => {
    let resp = await API.get(`/Bills/GetLastBuysByClient?storeClientId=${id}`);
    let lastBill = resp.data.map((bill: any) => {
      let prod: product[] = products.filter(
        (prod) => prod.id === bill.productId
      );
      return {
        guia: bill.guia,
        status: bill.status,
        quantity: bill.quantity,
        date: moment(bill.created_at).format("DD/MM/YYYY"),
        product: prod.length > 0 ? prod[0].name : "-",
      };
    });
    setLastBillsByClient(lastBill);
  };

  const saveStoreClient = async () => {
    if (storeClientExist) {
      //modificar datos del cliente
      let resp = await API.put(`/StoreClient/${storeClient.id}`, storeClient);
      return storeClient.id;
    } else {
      //crear el cliente
      let resp = await API.post(`/StoreClient`, storeClient);
      return resp.data.id;
    }
  };

  const saveBill = () => {
    setLoading(true);
    saveStoreClient().then(async (storeClientId) => {
      if (storeClientId !== 0) {
        let arrayProducts: string[] = bill_ProductsList.map(
          (prod: BillProducts) => {
            return ` ${prod.quantity}x ${products.filter((p: product) => p.id === prod.productId)[0].name
              }`;
          }
        );
        let stringProducts = arrayProducts.join();
        let bill: Bill = {
          guia: "",
          delivery_value: delivery_value,
          status: "Pendiente",
          payment_home: payment_home,
          collect_value: payment_home
            ? localStorage.currency == "COP"
              ? collect_value
              : usdCollectValue
            : 0,
          authorized: true,
          store_ClientId: storeClientId,
          storeId: parseInt(localStorage.storeID),
          origin_city: 6834,
          created_by: parseInt(localStorage.userID),
          bill_Products: bill_ProductsList,
          company_TransportId:
            selectedCompanyTransport !== undefined
              ? selectedCompanyTransport
              : 2,
          observaciones_Envio: `${stringProducts} / ${deliveryComments}`,
          deliveryInWarehouse: storePickup,
          countryId: countrySelected,
        };
        if (selectedCompanyTransport == 1) {
          bill.envia = {
            cod_FormaPago: 4,
            mPesoVolumen_K: 1,
            cod_Cuenta: payment_home ? 518 : 517,
            nom_remitente:
              storeData !== undefined ? `CREC - ${storeData.name}` : "-",
            dir_remitente: "Carrera 50 FF # 10 sur 150",
            tel_remitente: storeData !== undefined ? storeData.cel : "-",
            ced_remitente:
              storeData !== undefined ? storeData.identification : "-",
            texto_Guia: `Barrio:${storeClient.neighborhood} ${stringProducts} / ${deliveryComments}`,
          };
        }
        if (storePickup == true) {
          bill.delivery_value = 0;
          bill.company_TransportId = 4;
          bill.collect_value = 0;
          bill.status = "Pendiente";
        }
        API.post(`/Bills`, bill)
          .then((x) => {
            message.success("La factura se generó existosamente");
            setSelectedComponent("Sales");
          })
          .catch((err) => {
            message.error(
              `La transportadora no permitió crear esta guia. ${err}`,
              8
            );
            setLoading(false);
            console.log(err);
          });
      } else {
        message.error(
          "Se peresentó un error al guardar los datos, intentelo nuevamente",
          5
        );
        setLoading(false);
      }
    });
  };

  const onSearchFilteredProduct = (value: string) => {
    if (value) {
      let prods = products.filter((x: product) =>
        x.name.toLowerCase().includes(value.toLowerCase())
      );
      if (prods.length > 0) {
        setProductsListFiltered(prods);
      } else {
        setProductsListFiltered([]);
      }
    } else {
      setProductsListFiltered(products);
    }

  };

  const addBillProducts = (e: number) => {
    let product = products.filter((x: product) => x.id === e)[0];
    let billProducts = [...(bill_ProductsList as any)];
    let billProduct: BillProducts = {
      quantity: 1,
      productId: product.id,
      created_by: parseInt(localStorage.userID),
    };
    billProducts.push(billProduct);
    setBill_Products(billProducts);
    getSumCollectValue(billProducts);
    getSumCollectValueUsd(billProducts);
  };

  const getSumCollectValue = (billProducts: any) => {
    let suma = 0;
    billProducts.map((x: BillProducts, i: number) => {
      let price: any = products.filter(
        (prod: any) => prod.id === x.productId
      )[0];
      suma += price.suggested_selling_price * x.quantity;
      if (i + 1 === billProducts.length) {
        setCollect_value(suma);
      }
    });
  };

  const getSumCollectValueUsd = (billProducts: any) => {
    let suma = 0;
    billProducts.map((x: BillProducts, i: number) => {
      let price = products.filter((prod: any) => prod.id === x.productId)[0];
      suma += price.usD_Suggested_selling_price * x.quantity;
      if (i + 1 === billProducts.length) {
        setUsdCollectValue(parseFloat(suma.toFixed(2)));
      }
    });
  };

  const updateBillProductsState = (
    value: any,
    field: string,
    index: number
  ) => {
    let billProducts = [...(bill_ProductsList as any)];
    billProducts[index][field] = value;
    setBill_Products(billProducts);
    if (countrySelected === 3) {
      getSumCollectValueUsd(billProducts);
    }
    if (countrySelected === 1) {
      getSumCollectValue(billProducts);
    }
  };

  const deleteProduct = (i: number) => {
    let billProducts = [...(bill_ProductsList as any)];
    billProducts.splice(i, 1);
    setBill_Products(billProducts);
    getSumCollectValue(billProducts);
    if (countrySelected === 3) {
      getSumCollectValueUsd(billProducts);
    }
  };

  const GetCompanyTransport = async () => {
    let resp = await API.get<company_transport[]>("/CompanyTransports");
    setCompanyTransportList(resp.data);
  };

  const [popupWithScrollViewVisible, setPopupWithScrollViewVisible] = useState(false);

  const hide = useCallback(() => {
    setPopupWithScrollViewVisible(false);
  }, [setPopupWithScrollViewVisible]);

  const columns = [
    {
      title: "Guia",
      dataIndex: "guia",
      key: "guia",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Producto",
      dataIndex: "product",
      key: "quaproductntity",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
  ];


  const openWarningNotification = (text: string) => {
    Modal.warning({
      title: <b>ADVERTENCIA</b>,
      content: (
        <div style={{ textAlign: "center" }}>
          <img
            src="https://drophacking.blob.core.windows.net/images/Facturar/Advertencia.png"
            width={100}
          />
          <br />
          {text}
        </div>
      ),
    });
  };

  const UploadExcel = async (file: any) => {
    try {
      if (file.size > 1000000) {
        message.warning('El archivo no puede superar 1MB o 1.000 KB')
      } else {
        let formData = new FormData();
        formData.append('file', file);
        await API.post(`/MasiveUpload/ReadExcelFile`, formData).then((res: any) => {
          message.success(`${file.name} se ha cargado correctamente`);
        }).catch((error) => {
          notification.error({ message: error.response.data })
          console.error('Error al cargar el archivo', error.response.data);
        });
      }
    } catch (error) {
      message.error(`Error al cargar el archivo. ${error}`)
    }
  }

  const uploadExcel: any = {
    beforeUpload(file: any) {
      return new Promise(resolve => {
        const reader = new FileReader();
        UploadExcel(file)
        reader.readAsDataURL(file);
      });
    }
  };

  const downloadExcelFile = () => {
    API.get(`/MasiveUpload?storeId=${userData?.storeID}`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Plantilla_Carga_Masiva.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        notification.error(error.errorMessage)
        console.error('Error al descargar el archivo', error);
      });

  }

  const GetPresales = () => {
    API.get(`/PreBills`).then((response) => {
      setPrebills(response.data);
    })
      .catch((error) => {
        notification.error(error.errorMessage)
        console.error('Error al intentar obtener las preventas', error);
      });
  }
  return (
    <div className="billing-container">

      <Row>
        <Col span={3}></Col>
        <Col span={20}>

          {/*--------------------------Datos del cliente----------------------------------*/}
          {currentStep == "Datos del Cliente" && (
            <div>
              {storeClient.warningComments !== "" && !advertenciaMostrada && (
                <>
                  {setAdvertenciaMostrada(true)}
                  {openWarningNotification(storeClient.warningComments)}
                </>
              )}
              <img
                src="https://drophacking.blob.core.windows.net/images/Facturar/BannerDatosCliente.png"
                width={"100%"}
              />
              <div className="fondo-blanco">
                <Row>

                  <Col xs={24} sm={24} md={24} lg={22} xl={22} className="bg-1-sales">
                    <div onClick={() => {
                      setCurrentPreBills(preBills.filter(x => x.chanel == ChanelPreBill.Shopify))
                      setPopupWithScrollViewVisible(true);
                    }} className="menu-boton">
                      <img src="https://drophacking.blob.core.windows.net/images/Canales/icono-shopify.jpeg" />
                      Shopify ({preBills.filter(x => x.chanel == ChanelPreBill.Shopify).length})
                    </div>
                    <div className="menu-boton" onClick={() => {
                      setCurrentPreBills(preBills.filter(x => x.chanel == ChanelPreBill.SmartChat))
                      setPopupWithScrollViewVisible(true);
                    }}>
                      <img sizes="l" src="https://drophacking.blob.core.windows.net/images/Canales/smarchat.png" />
                      SmartChat ({preBills.filter(x => x.chanel == ChanelPreBill.SmartChat).length})
                    </div>
                  </Col>
                  <Popup
                    title="Pre ventas"
                    visible={popupWithScrollViewVisible && currentPreBills.length > 0}
                    onHiding={hide}
                    showCloseButton={true}
                  >

                    <div>

                      <TablePreBill data={currentPreBills} ></TablePreBill>
                    </div>

                  </Popup>

                </Row>
                <Row>
                  <Col span={1}></Col>
                  <Col span={2}>
                    <Button onClick={downloadExcelFile} className="generic-btn" icon={<DownloadOutlined />}>Descargar</Button>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={3}>
                    <Upload {...uploadExcel}>
                      <Button className="generic-btn" icon={<UploadOutlined />}>Carga Masiva</Button>
                    </Upload>
                  </Col>


                  <Col span={8} className="inputs-container">
                    <Input
                      placeholder="Número de Celular"
                      prefix={
                        <img
                          src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-CELULAR.png"
                          className="form-icon"
                        />
                      }
                      onChange={(e) =>
                        updateStoreClientState(e.target.value, "cellphone")
                      }
                      onBlur={(e) =>
                        findClientByPhone(e.target.value, "cellphone")
                      }
                      value={storeClient.cellphone}
                      className="input"
                    />
                  </Col>
                  <Col span={8} className="inputs-container">
                    <Input
                      placeholder={"Nombre y Apellidos"}
                      prefix={
                        <img
                          src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-NOMBRE.png"
                          className="form-icon"
                        />
                      }
                      onChange={(e) =>
                        updateStoreClientState(e.target.value, "name")
                      }
                      value={storeClient.name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="inputs-container">
                    <Input
                      placeholder="Número de Identificación o NIT"
                      prefix={
                        <img
                          src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-NUMERO-DE-IDENTIFICACIÓN.png"
                          className="form-icon"
                        />
                      }
                      onChange={(e) =>
                        updateStoreClientState(e.target.value, "identification")
                      }
                      value={storeClient.identification}
                    />
                  </Col>
                  <Col span={12} className="inputs-container">
                    <Input
                      placeholder="Correo Electrónico"
                      prefix={
                        <img
                          src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-CORREO-ELECTRONICO.png"
                          className="form-icon"
                        />
                      }
                      onChange={(e) =>
                        updateStoreClientState(e.target.value, "email")
                      }
                      value={storeClient.email}
                    />
                  </Col>
                </Row>

                <div style={{ marginTop: 15 }}>
                  <div style={{ color: "#021435", fontSize: 20 }}>
                    HISTORIAL DE <b>COMPRA DEL CLIENTE</b>
                  </div>
                  {lastBillsByClient.length > 0 && (
                    <Table
                      size={"small"}
                      columns={columns}
                      dataSource={lastBillsByClient}
                      pagination={{ defaultPageSize: 8 }}
                    />
                  )}
                </div>
                <Row gutter={[16, 16]}>
                  <Col span={10}></Col>
                  {
                    <Col span={3}>
                      <Button className="generic-btn"
                        disabled={!storeClient.cellphone || !storeClient.name || !storeClient.identification}
                        onClick={() => setCurrentStep("Localización y envío")}>
                        Siguiente {">"}
                      </Button>
                    </Col>
                  }
                </Row>
              </div>
            </div>
          )}

          {/*--------------------------Localización y envío----------------------------------*/}
          {currentStep == "Localización y envío" && (
            <div>
              <img
                src="https://drophacking.blob.core.windows.net/images/Facturar/BannerLocalization.png"
                width={"100%"}
              />
              <div className="fondo-blanco">
                <br />
                <Row>
                  <Col span={8} className="inputs-container">
                    <Select
                      className={"input"}
                      style={{ width: "100%" }}
                      placeholder={"Selecciona un país"}
                      value={parseInt(localStorage.country)}
                      disabled={true}
                      onChange={handleCountrySelect}
                    >
                      {countries.map((x: country) => (
                        <Option key={x.id} value={x.id}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={8} className="inputs-container">
                    <Select
                      placeholder="Ciudad"
                      className="input"
                      value={storeClient.cityId}
                      onChange={(text) =>
                        updateStoreClientState(text, "cityId")
                      }
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {citiesList.map((x) => (
                        <Option value={x.id} key={x.id}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={8} className="inputs-container">
                    <Select
                      style={{ width: "100%" }}
                      placeholder={"Selecciona una transportadora"}
                      value={selectedCompanyTransport}
                      onChange={(e) => {
                        let payment: company_transport =
                          companyTransportList.filter(
                            (x: company_transport) => x.id == e
                          )[0];
                        let cityCompany: cities_Company_Transports =
                          payment.cities_Company_Transports.filter(
                            (x: cities_Company_Transports) =>
                              x.cityId === storeClient.cityId
                          )[0];
                        setSelectedCompanyTransport(e);
                        setPayment_home(cityCompany.payment_home);
                        setPayment_home_disabled(!cityCompany.payment_home);
                      }}
                    >
                      {companyTransportList.map((x: company_transport) => (
                        <Option
                          key={x.id}
                          value={x.id}
                          disabled={
                            x.cities_Company_Transports
                              .map(function (e: cities_Company_Transports) {
                                return e.cityId;
                              })
                              .indexOf(storeClient.cityId) === -1
                          }
                        >
                          <img
                            src={x.iconPath}
                            style={{ width: 40, marginRight: 20 }}
                          />
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                {localStorage.currency == "COP" ? (
                  <Row>
                    <Col span={3} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Calle"
                        value={storeClient.carrera}
                        onChange={(text) =>
                          updateStoreClientState(text, "carrera")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="Avenida" key="Avenida">
                          Avenida
                        </Option>
                        <Option value="Autopista" key="Autopista">
                          Autopista
                        </Option>
                        <Option value="Calle" key="Calle">
                          Calle
                        </Option>
                        <Option value="Carrera" key="Carrera">
                          Carrera
                        </Option>
                        <Option value="Circular" key="Circular">
                          Circular
                        </Option>
                        <Option value="Diagonal" key="Diagonal">
                          Diagonal
                        </Option>
                        <Option value="Transversal" key="Transversal">
                          Transversal
                        </Option>
                        <Option value="Troncal" key="Troncal">
                          Troncal
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Input
                        placeholder="Ej: 85"
                        value={storeClient.adress_field_1}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "adress_field_1"
                          )
                        }
                      />
                    </Col>

                    <Col span={3} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Ej: A / aa"
                        value={storeClient.adress_field_2}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_2")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="A" key="A">
                          A
                        </Option>
                        <Option value="AA" key="AA">
                          AA
                        </Option>
                        <Option value="AB" key="AB">
                          AB
                        </Option>
                        <Option value="AC" key="AC">
                          AC
                        </Option>
                        <Option value="AD" key="AD">
                          AD
                        </Option>
                        <Option value="AE" key="AE">
                          AE
                        </Option>
                        <Option value="AF" key="AF">
                          AF
                        </Option>
                        <Option value="AG" key="AG">
                          AG
                        </Option>
                        <Option value="AH" key="AH">
                          AH
                        </Option>
                        <Option value="AN" key="AN">
                          AN
                        </Option>
                        <Option value="AW" key="AW">
                          AW
                        </Option>
                        <Option value="B" key="B">
                          B
                        </Option>
                        <Option value="BB" key="BB">
                          BB
                        </Option>
                        <Option value="BA" key="BA">
                          BA
                        </Option>
                        <Option value="BC" key="BC">
                          BC
                        </Option>
                        <Option value="BD" key="BD">
                          BD
                        </Option>
                        <Option value="BE" key="BE">
                          BE
                        </Option>
                        <Option value="BF" key="BF">
                          BF
                        </Option>
                        <Option value="BG" key="BG">
                          BG
                        </Option>
                        <Option value="BH" key="BH">
                          BH
                        </Option>
                        <Option value="BN" key="BN">
                          BN
                        </Option>
                        <Option value="C" key="C">
                          C
                        </Option>
                        <Option value="CC" key="CC">
                          CC
                        </Option>
                        <Option value="CA" key="CA">
                          CA
                        </Option>
                        <Option value="CB" key="CB">
                          CB
                        </Option>
                        <Option value="CD" key="CD">
                          CD
                        </Option>
                        <Option value="CE" key="CE">
                          CE
                        </Option>
                        <Option value="CF" key="CF">
                          CF
                        </Option>
                        <Option value="CG" key="CG">
                          CG
                        </Option>
                        <Option value="CH" key="CH">
                          CH
                        </Option>
                        <Option value="D" key="D">
                          D
                        </Option>
                        <Option value="DD" key="DD">
                          DD
                        </Option>
                        <Option value="DA" key="DA">
                          DA
                        </Option>
                        <Option value="DB" key="DB">
                          DB
                        </Option>
                        <Option value="DC" key="DC">
                          DC
                        </Option>
                        <Option value="DE" key="DE">
                          DE
                        </Option>
                        <Option value="DF" key="DF">
                          DF
                        </Option>
                        <Option value="DG" key="DG">
                          DG
                        </Option>
                        <Option value="DH" key="DH">
                          DH
                        </Option>
                        <Option value="E" key="E">
                          E
                        </Option>
                        <Option value="EE" key="EE">
                          EE
                        </Option>
                        <Option value="EA" key="EA">
                          EA
                        </Option>
                        <Option value="EB" key="EB">
                          EB
                        </Option>
                        <Option value="EC" key="EC">
                          EC
                        </Option>
                        <Option value="ED" key="ED">
                          ED
                        </Option>
                        <Option value="EF" key="EF">
                          EF
                        </Option>
                        <Option value="EG" key="EG">
                          EG
                        </Option>
                        <Option value="EH" key="EH">
                          EH
                        </Option>
                        <Option value="F" key="F">
                          F
                        </Option>
                        <Option value="FF" key="FF">
                          FF
                        </Option>
                        <Option value="FA" key="FA">
                          FA
                        </Option>
                        <Option value="FB" key="FB">
                          FB
                        </Option>
                        <Option value="FC" key="FC">
                          FC
                        </Option>
                        <Option value="FD" key="FD">
                          FD
                        </Option>
                        <Option value="FE" key="FE">
                          FE
                        </Option>
                        <Option value="FG" key="FG">
                          FG
                        </Option>
                        <Option value="FH" key="FH">
                          FH
                        </Option>
                        <Option value="G" key="G">
                          G
                        </Option>
                        <Option value="GG" key="GG">
                          GG
                        </Option>
                        <Option value="GA" key="GA">
                          GA
                        </Option>
                        <Option value="GB" key="GB">
                          GB
                        </Option>
                        <Option value="GC" key="GC">
                          GC
                        </Option>
                        <Option value="GD" key="GD">
                          GD
                        </Option>
                        <Option value="GE" key="GE">
                          GE
                        </Option>
                        <Option value="GF" key="GF">
                          GF
                        </Option>
                        <Option value="GH" key="GH">
                          GH
                        </Option>
                        <Option value="GN" key="GN">
                          GN
                        </Option>
                        <Option value="H" key="H">
                          H
                        </Option>
                        <Option value="HH" key="HH">
                          HH
                        </Option>
                        <Option value="HA" key="HA">
                          HA
                        </Option>
                        <Option value="HB" key="HB">
                          HB
                        </Option>
                        <Option value="HC" key="HC">
                          HC
                        </Option>
                        <Option value="HD" key="HD">
                          HD
                        </Option>
                        <Option value="HE" key="HE">
                          HE
                        </Option>
                        <Option value="HF" key="HF">
                          HF
                        </Option>
                        <Option value="HG" key="HG">
                          HG
                        </Option>
                        <Option value="I" key="I">
                          I
                        </Option>
                        <Option value="J" key="J">
                          J
                        </Option>
                        <Option value="K" key="K">
                          K
                        </Option>
                        <Option value="L" key="L">
                          L
                        </Option>
                        <Option value="M" key="M">
                          M
                        </Option>
                        <Option value="N" key="N">
                          N
                        </Option>
                        <Option value="O" key="O">
                          O
                        </Option>
                        <Option value="P" key="P">
                          P
                        </Option>
                        <Option value="Q" key="Q">
                          Q
                        </Option>
                        <Option value="R" key="R">
                          R
                        </Option>
                        <Option value="S" key="S">
                          S
                        </Option>
                        <Option value="T" key="T">
                          T
                        </Option>
                        <Option value="U" key="U">
                          U
                        </Option>
                        <Option value="V" key="V">
                          V
                        </Option>
                        <Option value="W" key="W">
                          W
                        </Option>
                        <Option value="X" key="X">
                          X
                        </Option>
                        <Option value="Y" key="Y">
                          Y
                        </Option>
                        <Option value="Z" key="Z">
                          Z
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Sur / Norte"
                        value={storeClient.adress_field_3}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_3")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="Sur" key="Sur">
                          Sur
                        </Option>
                        <Option value="Bis" key="Bis">
                          Bis
                        </Option>
                        <Option value="Bis1" key="Bis1">
                          Bis1
                        </Option>
                        <Option value="Bis2" key="Bis2">
                          Bis2
                        </Option>
                        <Option value="Oeste" key="Oeste">
                          Oeste
                        </Option>
                        <Option value="Este" key="Este">
                          Este
                        </Option>
                        <Option value="Norte" key="Norte">
                          Norte
                        </Option>
                        <Option value="Avenida" key="Avenida">
                          Avenida
                        </Option>
                        <Option value="Autopista" key="Autopista">
                          Autopista
                        </Option>
                        <Option value="Calle" key="Calle">
                          Calle
                        </Option>
                        <Option value="Carrera" key="Carrera">
                          Carrera
                        </Option>
                        <Option value="Circular" key="Circular">
                          Circular
                        </Option>
                        <Option value="Diagonal" key="Diagonal">
                          Diagonal
                        </Option>
                        <Option value="Transversal" key="Transversal">
                          Transversal
                        </Option>
                        <Option value="Troncal" key="Troncal">
                          Troncal
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Input
                        placeholder="# 35"
                        value={storeClient.adress_field_4}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "adress_field_4"
                          )
                        }
                      />
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Ej: F"
                        value={storeClient.adress_field_5}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_5")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="A" key="A">
                          A
                        </Option>
                        <Option value="AA" key="AA">
                          AA
                        </Option>
                        <Option value="AB" key="AB">
                          AB
                        </Option>
                        <Option value="AC" key="AC">
                          AC
                        </Option>
                        <Option value="AD" key="AD">
                          AD
                        </Option>
                        <Option value="AE" key="AE">
                          AE
                        </Option>
                        <Option value="AF" key="AF">
                          AF
                        </Option>
                        <Option value="AG" key="AG">
                          AG
                        </Option>
                        <Option value="AH" key="AH">
                          AH
                        </Option>
                        <Option value="AN" key="AN">
                          AN
                        </Option>
                        <Option value="AW" key="AW">
                          AW
                        </Option>
                        <Option value="B" key="B">
                          B
                        </Option>
                        <Option value="BB" key="BB">
                          BB
                        </Option>
                        <Option value="BA" key="BA">
                          BA
                        </Option>
                        <Option value="BC" key="BC">
                          BC
                        </Option>
                        <Option value="BD" key="BD">
                          BD
                        </Option>
                        <Option value="BE" key="BE">
                          BE
                        </Option>
                        <Option value="BF" key="BF">
                          BF
                        </Option>
                        <Option value="BG" key="BG">
                          BG
                        </Option>
                        <Option value="BH" key="BH">
                          BH
                        </Option>
                        <Option value="BN" key="BN">
                          BN
                        </Option>
                        <Option value="C" key="C">
                          C
                        </Option>
                        <Option value="CC" key="CC">
                          CC
                        </Option>
                        <Option value="CA" key="CA">
                          CA
                        </Option>
                        <Option value="CB" key="CB">
                          CB
                        </Option>
                        <Option value="CD" key="CD">
                          CD
                        </Option>
                        <Option value="CE" key="CE">
                          CE
                        </Option>
                        <Option value="CF" key="CF">
                          CF
                        </Option>
                        <Option value="CG" key="CG">
                          CG
                        </Option>
                        <Option value="CH" key="CH">
                          CH
                        </Option>
                        <Option value="D" key="D">
                          D
                        </Option>
                        <Option value="DD" key="DD">
                          DD
                        </Option>
                        <Option value="DA" key="DA">
                          DA
                        </Option>
                        <Option value="DB" key="DB">
                          DB
                        </Option>
                        <Option value="DC" key="DC">
                          DC
                        </Option>
                        <Option value="DE" key="DE">
                          DE
                        </Option>
                        <Option value="DF" key="DF">
                          DF
                        </Option>
                        <Option value="DG" key="DG">
                          DG
                        </Option>
                        <Option value="DH" key="DH">
                          DH
                        </Option>
                        <Option value="E" key="E">
                          E
                        </Option>
                        <Option value="EE" key="EE">
                          EE
                        </Option>
                        <Option value="EA" key="EA">
                          EA
                        </Option>
                        <Option value="EB" key="EB">
                          EB
                        </Option>
                        <Option value="EC" key="EC">
                          EC
                        </Option>
                        <Option value="ED" key="ED">
                          ED
                        </Option>
                        <Option value="EF" key="EF">
                          EF
                        </Option>
                        <Option value="EG" key="EG">
                          EG
                        </Option>
                        <Option value="EH" key="EH">
                          EH
                        </Option>
                        <Option value="F" key="F">
                          F
                        </Option>
                        <Option value="FF" key="FF">
                          FF
                        </Option>
                        <Option value="FA" key="FA">
                          FA
                        </Option>
                        <Option value="FB" key="FB">
                          FB
                        </Option>
                        <Option value="FC" key="FC">
                          FC
                        </Option>
                        <Option value="FD" key="FD">
                          FD
                        </Option>
                        <Option value="FE" key="FE">
                          FE
                        </Option>
                        <Option value="FG" key="FG">
                          FG
                        </Option>
                        <Option value="FH" key="FH">
                          FH
                        </Option>
                        <Option value="G" key="G">
                          G
                        </Option>
                        <Option value="GG" key="GG">
                          GG
                        </Option>
                        <Option value="GA" key="GA">
                          GA
                        </Option>
                        <Option value="GB" key="GB">
                          GB
                        </Option>
                        <Option value="GC" key="GC">
                          GC
                        </Option>
                        <Option value="GD" key="GD">
                          GD
                        </Option>
                        <Option value="GE" key="GE">
                          GE
                        </Option>
                        <Option value="GF" key="GF">
                          GF
                        </Option>
                        <Option value="GH" key="GH">
                          GH
                        </Option>
                        <Option value="GN" key="GN">
                          GN
                        </Option>
                        <Option value="H" key="H">
                          H
                        </Option>
                        <Option value="HH" key="HH">
                          HH
                        </Option>
                        <Option value="HA" key="HA">
                          HA
                        </Option>
                        <Option value="HB" key="HB">
                          HB
                        </Option>
                        <Option value="HC" key="HC">
                          HC
                        </Option>
                        <Option value="HD" key="HD">
                          HD
                        </Option>
                        <Option value="HE" key="HE">
                          HE
                        </Option>
                        <Option value="HF" key="HF">
                          HF
                        </Option>
                        <Option value="HG" key="HG">
                          HG
                        </Option>
                        <Option value="I" key="I">
                          I
                        </Option>
                        <Option value="J" key="J">
                          J
                        </Option>
                        <Option value="K" key="K">
                          K
                        </Option>
                        <Option value="L" key="L">
                          L
                        </Option>
                        <Option value="M" key="M">
                          M
                        </Option>
                        <Option value="N" key="N">
                          N
                        </Option>
                        <Option value="O" key="O">
                          O
                        </Option>
                        <Option value="P" key="P">
                          P
                        </Option>
                        <Option value="Q" key="Q">
                          Q
                        </Option>
                        <Option value="R" key="R">
                          R
                        </Option>
                        <Option value="S" key="S">
                          S
                        </Option>
                        <Option value="T" key="T">
                          T
                        </Option>
                        <Option value="U" key="U">
                          U
                        </Option>
                        <Option value="V" key="V">
                          V
                        </Option>
                        <Option value="W" key="W">
                          W
                        </Option>
                        <Option value="X" key="X">
                          X
                        </Option>
                        <Option value="Y" key="Y">
                          Y
                        </Option>
                        <Option value="Z" key="Z">
                          Z
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Sur / Norte"
                        value={storeClient.adress_field_6}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_6")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="Sur" key="Sur">
                          Sur
                        </Option>
                        <Option value="Bis" key="Bis">
                          Bis
                        </Option>
                        <Option value="Bis1" key="Bis1">
                          Bis1
                        </Option>
                        <Option value="Bis2" key="Bis2">
                          Bis2
                        </Option>
                        <Option value="Oeste" key="Oeste">
                          Oeste
                        </Option>
                        <Option value="Este" key="Este">
                          Este
                        </Option>
                        <Option value="Norte" key="Norte">
                          Norte
                        </Option>
                        <Option value="Avenida" key="Avenida">
                          Avenida
                        </Option>
                        <Option value="Autopista" key="Autopista">
                          Autopista
                        </Option>
                        <Option value="Calle" key="Calle">
                          Calle
                        </Option>
                        <Option value="Carrera" key="Carrera">
                          Carrera
                        </Option>
                        <Option value="Circular" key="Circular">
                          Circular
                        </Option>
                        <Option value="Diagonal" key="Diagonal">
                          Diagonal
                        </Option>
                        <Option value="Transversal" key="Transversal">
                          Transversal
                        </Option>
                        <Option value="Troncal" key="Troncal">
                          Troncal
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Input
                        placeholder="Ej: 45"
                        value={storeClient.adress_field_7}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "adress_field_7"
                          )
                        }
                      />
                    </Col>
                    <Col span={4} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Bloque / Torre"
                        value={storeClient.adress_field_10}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_10")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="Bloque" key="Bloque">
                          Bloque
                        </Option>
                        <Option value="Torre" key="Torre">
                          Torre
                        </Option>
                        <Option value="Condominio" key="Condominio">
                          Condominio
                        </Option>
                        <Option value="Manzana" key="Manzana">
                          Manzana
                        </Option>
                        <Option value="Fase" key="Fase">
                          Fase
                        </Option>
                        <Option value="Etapa" key="Etapa">
                          Etapa
                        </Option>
                        <Option value="Piso" key="Piso">
                          Piso
                        </Option>
                        <Option value="Interior" key="Interior">
                          Interior
                        </Option>
                      </Select>
                    </Col>
                    <Col span={2} className="inputs-container">
                      <Input
                        placeholder="3"
                        value={storeClient.adress_field_11}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "adress_field_11"
                          )
                        }
                      />
                    </Col>
                    <Col span={4} className="inputs-container">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Casa / Apartamento"
                        value={storeClient.adress_field_8}
                        onChange={(text) =>
                          updateStoreClientState(text, "adress_field_8")
                        }
                      >
                        <Option value="" key="-">
                          -
                        </Option>
                        <Option value="Casa" key="Casa">
                          Casa
                        </Option>
                        <Option value="Apartamento" key="Apartamento">
                          Apartamento
                        </Option>
                        <Option value="Interior" key="Interior">
                          Interior
                        </Option>
                        <Option value="Local" key="Local">
                          Local
                        </Option>
                        <Option value="Oficina" key="Oficina">
                          Oficina
                        </Option>
                        <Option value="Bodega" key="Bodega">
                          Bodega
                        </Option>
                        <Option value="Consultorio" key="Consultorio">
                          Consultorio
                        </Option>
                        <Option value="Esquina" key="Esquina">
                          Esquina
                        </Option>
                        <Option value="Habitación" key="Habitación">
                          Habitación
                        </Option>
                        <Option value="Finca" key="Finca">
                          Finca
                        </Option>
                        <Option value="Hotel" key="Hotel">
                          Hotel
                        </Option>
                        <Option value="Hospital" key="Hospital">
                          Hospital
                        </Option>
                        <Option value="Colegio" key="Colegio">
                          Colegio
                        </Option>
                        <Option value="Lote" key="Lote">
                          Lote
                        </Option>
                        <Option value="Universidad" key="Universidad">
                          Universidad
                        </Option>
                      </Select>
                    </Col>
                    <Col span={3} className="inputs-container">
                      <Input
                        placeholder="Interior"
                        type="number"
                        value={storeClient.adress_field_9}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "adress_field_9"
                          )
                        }
                      />
                    </Col>
                    <Col span={4} className="inputs-container">
                      <Input
                        placeholder="Barrio"
                        maxLength={30}
                        value={storeClient.neighborhood}
                        onChange={(text) =>
                          updateStoreClientState(
                            text.target.value,
                            "neighborhood"
                          )
                        }
                      />
                      <div style={{ fontSize: 11, color: "grey" }}>
                        Máximo 30 caracteres ({storeClient.neighborhood.length}{" "}
                        / 30)
                      </div>
                    </Col>
                    <Col span={7} className="inputs-container">
                      <Input
                        placeholder="Edificio / Unidad / Conjunto"
                        value={storeClient.building}
                        onChange={(text) =>
                          updateStoreClientState(text.target.value, "building")
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                  <Col span={24} className="inputs-container">
                    <Input
                      placeholder="Dirección"
                      value={storeClient.adress_field_1}
                      onChange={(text) =>
                        updateStoreClientState(
                          text.target.value,
                          "adress_field_1"
                        )
                      }
                    />
                  </Col>
                )}
                <Row>
                  <Col span={12} className="inputs-container">
                    <Input
                      placeholder=" Indicaciones Adicionales"
                      maxLength={70}
                      value={storeClient.adress_indications}
                      onChange={(text) =>
                        updateStoreClientState(
                          text.target.value,
                          "adress_indications"
                        )
                      }
                    />
                    <div style={{ fontSize: 12, color: "grey" }}>
                      Máximo 70 caracteres (
                      {storeClient.adress_indications.length} / 70)
                    </div>
                  </Col>
                  <Col span={12} className="inputs-container">
                    <Input
                      placeholder="Observaciones de envío"
                      maxLength={50}
                      value={deliveryComments}
                      onChange={(text) =>
                        setDeliveryComments(text.target.value)
                      }
                    />
                    <div style={{ fontSize: 12, color: "grey" }}>
                      Máximo 50 caracteres ({deliveryComments.length} / 50)
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div style={{ color: "#021435", fontSize: 15 }}>
                      <b>DIRECCIÓN</b>
                    </div>
                    <br />
                    <b>
                      {`${storeClient.carrera} ${storeClient.adress_field_1} 
                ${storeClient.adress_field_2} ${storeClient.adress_field_3} 
                ${storeClient.adress_field_1 !== "" ? "#" : ""} 
                ${storeClient.adress_field_4} ${storeClient.adress_field_5} ${storeClient.adress_field_6
                        }
                ${storeClient.adress_field_7 !== "" ? "-" : ""} ${storeClient.adress_field_7
                        }`}
                    </b>
                  </Col>
                  <Col span={12}>
                    <b>
                      {`${storeClient.adress_field_10} ${storeClient.adress_field_11} 
                  ${storeClient.adress_field_8} ${storeClient.adress_field_9}`}
                    </b>
                    <br />
                    <b>
                      {storeClient.building !== ""
                        ? "Edificio / Unidad / Conjunto:"
                        : ""}{" "}
                      {storeClient.building}
                    </b>
                    <br />
                    <b>
                      {(storeClient.neighborhood !== "" && countrySelected == 1) ? "Barrio:" : ""}{" "}
                      {countrySelected == 1 && (storeClient.neighborhood)}
                    </b>
                    <br />
                    <b>{storeClient.adress_indications}</b>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={9}></Col>
                  <Col span={3}>
                    <Button
                      className="generic-btn"
                      onClick={() => setCurrentStep("Datos del Cliente")}
                    >
                      {"<"} Atrás
                    </Button>
                  </Col>
                  {
                    <Col span={3}>
                      <Button
                        disabled={!selectedCompanyTransport || ((storeClient.carrera === "" && storeClient.adress_field_1 === "" &&

                          storeClient.adress_field_4 === "" && storeClient.adress_field_5 === "" && storeClient.adress_field_6 === "" &&

                          storeClient.adress_field_7 === "" && storeClient.adress_field_8 === "" && storeClient.adress_field_9 === "" &&
                          storeClient.adress_field_10 === "" && storeClient.adress_field_11 === "" && storeClient.neighborhood === "" && storeClient.building === "") && storeClient.adress_indications === ""
                        )}
                        className="generic-btn"
                        onClick={() => setCurrentStep("Productos")}
                      >
                        Siguiente {">"}
                      </Button>
                    </Col>
                  }
                </Row>
              </div>
            </div>
          )}

          {/*--------------------------Productos----------------------------------*/}
          {currentStep == "Productos" && (
            <div>
              <img
                src="https://drophacking.blob.core.windows.net/images/Facturar/BannerProductos.png"
                width={"100%"}
              />
              <div className="fondo-blanco">
                <br />
                <div style={{ color: "#021435", fontSize: 25 }}>
                  DETALLES <b>DEL ENVÍO</b>
                </div>
                <br />
                <Row>
                  <Col span={12} className="inputs-container">
                    <Select
                      style={{ width: "100%", marginBottom: 20 }}
                      placeholder={"Selecciona un producto"}
                      defaultActiveFirstOption={false}
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchFilteredProduct}
                      onChange={(e: any) => {
                        addBillProducts(e);
                      }}
                      notFoundContent={null}
                    >
                      {productsListFiltered.map((x: product) => (
                        <Option value={x.id} key={x.id}>
                          <Row>
                            <Col span={24}>{x.name}</Col>
                            {/*  <Col span={6}>
                              <span style={{ color: "gray", fontSize: 12 }}>
                                {inventory.length > 0 &&
                                  inventory.filter(
                                    (i) => i.productId == x.id
                                  )[0].totalInventory}
                                &nbsp; Unds
                              </span>
                            </Col> */}
                          </Row>
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12} className="inputs-container">
                    {bill_ProductsList.length > 0 && (
                      <Row style={{ fontWeight: "bold", marginBottom: 10 }}>
                        <Col span={14}>Producto</Col>
                        <Col span={4}>Valor</Col>
                        <Col span={4}>Cantidad</Col>
                      </Row>
                    )}
                    {bill_ProductsList.map((x, i) => (
                      <>
                        <Row
                          gutter={[16, 16]}
                          style={{
                            fontSize: 12,
                            paddingBottom: 3,
                            marginBottom: 3,
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          <Col span={14}>
                            {
                              products.filter((p) => p.id == x.productId)[0]
                                .name
                            }
                          </Col>
                          <></>
                          <Col span={4}>
                            {localStorage.currency == "COP" ? (
                              <>
                                {`$ ${products.filter((p) => p.id == x.productId)[0]
                                  .selling_price
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </>
                            ) : (
                              <>
                                {`$ ${products.filter((p) => p.id == x.productId)[0]
                                  .usD_Selling_price
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </>
                            )}
                          </Col>
                          <Col span={4}>
                            <InputNumber
                              onChange={(e) =>
                                updateBillProductsState(e, "quantity", i)
                              }
                              value={x.quantity}
                              style={{
                                width: "90%",
                                borderRadius: "10px 0px 0px 10px",
                              }}
                              min={1}
                            />
                          </Col>
                          <Col span={2}>
                            <Popconfirm
                              placement="top"
                              title={"¿ Eliminar este producto ?"}
                              onConfirm={() => deleteProduct(i)}
                              okText="Sí"
                              cancelText="No"
                            >
                              <DeleteOutlined style={{ color: "red" }} />
                            </Popconfirm>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="inputs-container">
                    <b>Valor a recaudar:</b>
                    <InputNumber
                      style={{
                        width: "100%",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      prefix={
                        <img
                          src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-VALOR.png"
                          className="form-icon"
                        />
                      }
                      value={
                        localStorage.currency == "COP"
                          ? collect_value
                          : usdCollectValue
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={(e) =>
                        localStorage.currency == "COP"
                          ? setCollect_value(e)
                          : setUsdCollectValue(e)
                      }
                    />
                  </Col>
                  <Col span={12} className="inputs-container">
                    <Col span={10}>
                      Pago contra entrega:&nbsp;
                      <Switch
                        size="small"
                        checked={payment_home}
                        onChange={() => {
                          setPayment_home(!payment_home);
                          if (!payment_home) {
                            setStorePickup(false);
                          }
                        }}
                        disabled={payment_home_disabled}
                      />
                    </Col>
                    <Col span={10}>
                      Recoger en CRECEL:&nbsp;
                      <Switch
                        size="small"
                        checked={storePickup}
                        onChange={() => {
                          if (!storePickup) {
                            setPayment_home(false);
                          }
                          setStorePickup(!storePickup);
                        }}
                        disabled={storeClient.cityId != 6834}
                      />
                    </Col>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={6}></Col>
                  <Col span={3}>
                    <div
                      className="generic-btn"
                      onClick={() => setCurrentStep("Localización y envío")}
                    >
                      {"<"} Atrás
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{ backgroundColor: "gray" }}
                      className="generic-btn"
                      onClick={previewModal}
                    >
                      Vista previa de la guía
                    </div>
                  </Col>

                  {bill_ProductsList.length > 0 && (
                    <Col span={3}>
                      <Spin tip="Guardando..." spinning={loading}>
                        <div className="generic-btn" onClick={() => saveBill()}>
                          Enviar {">"}
                        </div>
                      </Spin>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {visible && (
        <ModalDetalleGuia
          visible={visible}
          setVisible={setVisible}
          guia={""}
          BillData={billData}
        />
      )}
    </div>
  );
};

export default GenerateBill;
