import { Badge } from "antd";
import moment from "moment";
import { useState } from "react";
import { API } from "../API/axios";
import { Bill } from "../Models/Bill";
import { city } from "../Models/city.model";
import { company_transport } from "../Models/company_Transport";
import { BillProducts, product } from "../Models/Products";
import { storeModel } from "../Models/store";
import { BillByStatus, LabelModel } from "../Models/Warehouse";

const useWarehouse = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLabels, setdataLabels] = useState<Array<Bill>>([]);
  const [citiesList, setCitiesList] = useState<Array<city>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataGuias, setdataGuias] = useState<Array<Bill>>([]);
  const [storesList, setStoresList] = useState<Array<storeModel>>([]);
  const [labelsList, setLabelsList] = useState<Array<LabelModel>>([]);
  const [billsArrayList, setBillsArrayList] = useState<Array<Bill>>([]);
  const [ModalVisible, setModalVisible] = useState(false);
  const [ModalDetailBillVisible, setModalDetailBillVisible] = useState<boolean>(false);
  const [selectedGuia, setSelectedGuia] = useState<string>('');
  const [printMode, setPrintMode] = useState<'Rotulos' | 'Guias'>('Rotulos');
  const [selectedCompanyTransport, setSelectedCompanyTransport] = useState<number>(-1);
  const [companyTransportList, setCompanyTransportList] = useState<
    Array<company_transport>
  >([]);
  const [productList, setProductList] = useState<Array<product>>([]);

  const getPendingBillsByStatus = async (status: string) => {
    let array: any = [];
    setLoading(true);
    let bills = await API.get<Bill[]>(
      `/Bills/GetBillsByStatus?Status=${status}`
    );
    bills.data.map((x: Bill) => {
      x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
      x.key = x.id;
      array.push(x);
    });
    //Pendientes por anular
    let AnulationBills = await API.get<Bill[]>(
      `/Bills/GetBillsByStatus?Status=Anulación Solicitada`
    );
    AnulationBills.data.map((x: Bill) => {
      x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
      x.key = x.id;
      array.push(x);
    });
    setLoading(false);
    setdataLabels(array.reverse());
  };

  const getProductList = async () => {
    var resp = await API.get<product[]>(`/Products`)
    if (resp.status == 200) {
      setProductList(resp.data);
    }
  }

  const getCompanyTransports = async () => {
    var resp = await API.get<company_transport[]>(`/CompanyTransports`);
    if (resp.status == 200) {
      setCompanyTransportList(resp.data);
    }
  };

  const getCities = async () => {
    var resp = await API.get<city[]>(`/Cities`);
    if (resp.status == 200) {
      setCitiesList(resp.data);
    }
  }

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (e: any) => onSelectChange(e),
  };

  const columnsLabels = [
    {
      title: "Guia",
      dataIndex: "guia",
      key: "guia",
      render: (f: any, x: Bill) => {
        return <a onClick={() => openDetailGuia(x.guia)}>{(x.status == 'Pendiente' || x.status == 'Impreso-R') ? x.guia : <div style={{ color: 'red' }}> {x.guia}</div>}</a>
      }
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Transportadora",
      dataIndex: "company_TransportId",
      key: "company_TransportId",
      render: (f: any, x: Bill) => {
        let company: company_transport[] = companyTransportList.filter(
          (comp: company_transport) => x.company_TransportId === comp.id
        );
        return company.length > 0 ? company[0].name : "-";
      },
    },
    {
      title: "Venta",
      dataIndex: "bill_Products",
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter(
            (p: product) => prod.productId === p.id
          );
          return productFiltered.length > 0
            ? `${prod.quantity} ${productFiltered[0].name}`
            : "";
        });
        return arrayProds.join();
      },
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      render: (f: any, x: Bill) => {
        let cityId: any = x.cityId ?? x.storeClient?.cityId
        let cityClient: city[] = citiesList.filter(
          (city: city) => city.id === cityId
        );
        return cityClient.length > 0
          ? `${cityClient[0].name} (${cityClient[0].provinceName})`
          : "";
      },
    },
    {
      title: "Recaudo",
      dataIndex: "collect_value",
      key: "collect_value",
      render: (f: any, x: Bill) => {
        return `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  const printLabels = (printMode: 'Rotulos' | "Guias") => {
    let array: LabelModel[] = [];
    let billsArray: Bill[] = [];
    selectedRowKeys.map(x => {
      let item: Bill[] = [];
      if (printMode === 'Rotulos') {
        item = dataLabels.filter((bill: Bill) => bill.id === x);
      }
      else {
        item = dataGuias.filter((bill: Bill) => bill.id === x);
      }
      if (item.length > 0) {
        billsArray.push(item[0]);
        let arrayProds = item[0].bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? `${prod.quantity} ${productFiltered[0].name}` : ""
        });
        let arrayProdsValue = item[0].bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter((p: product) => prod.productId === p.id);
          return productFiltered.length > 0 ? productFiltered[0].selling_price : ""
        });
        let total = item[0].collect_value;
        //let total = arrayProdsValue.length > 0 ? arrayProdsValue.reduce((a:any, b:any) => {return a + b}) : 0;
        let StoreClientData: any = item[0].storeClient;
        let address = item[0].address != undefined ? item[0].address : `${StoreClientData.carrera} ${StoreClientData.adress_field_1} ${StoreClientData.adress_field_2} ${StoreClientData.adress_field_3} 
        ${StoreClientData.adress_field_1 !== "" ? "#" : ""} 
        ${StoreClientData.adress_field_4} ${StoreClientData.adress_field_5} ${StoreClientData.adress_field_6}
        ${StoreClientData.adress_field_7 !== "" ? "-" : ""} ${StoreClientData.adress_field_7} 
        ${StoreClientData.adress_field_8} ${StoreClientData.adress_field_9}
        ${StoreClientData.adress_field_10} ${StoreClientData.adress_field_11} ${StoreClientData.building}
        ${StoreClientData.adress_indications}`
        let city = item[0].cityId != undefined ? citiesList.find((city: city) => city.id === item[0].cityId) : citiesList.find((city: city) => city.id === StoreClientData.cityId);
        let el: LabelModel = {
          date: moment(item[0].created_at).format("YYYY-MM-DD"),
          store: item[0].storeName ? item[0].storeName : "",
          contactPhone: item[0].storePhone ? item[0].storePhone : "",
          addressee: StoreClientData.name,
          delivery_address: address,
          neighborhood: item[0].neighborhood ?? StoreClientData.neighborhood,
          city: city !== undefined ? `${city.name} (${city.provinceName})` : "-",
          celphone: StoreClientData.cellphone,
          indications: item[0].adress_indications ?? StoreClientData.adress_indications,
          products: arrayProds.join(),
          collect_value: `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          observations: item[0].observaciones_Envio,
          guia: item[0].guia,
          payment_home: item[0].payment_home,
          billId: item[0].id != undefined ? item[0].id : 0,
          deliveryInWarehouse: item[0].deliveryInWarehouse,
          clientIdentificacion: StoreClientData.identification,
          storeIdentification: item[0].storeIdentification ?? ""
        }
        array.push(el);
      }
    });
    setLabelsList(array);
    setBillsArrayList(billsArray)
    if (printMode === 'Rotulos') {
      setPrintMode('Rotulos')
      setModalVisible(true);
    }
    else {
      setPrintMode('Guias');
      setModalVisible(true);
    }
  }

  const openDetailGuia = (guia: string) => {
    setSelectedGuia(guia);
    setModalDetailBillVisible(true);
  }

  const getPendingBillsGuias = () => {

    setLoading(true);
    API.get<Bill[]>(`/Bills/GetBillsByStatus?Status=Impreso-R`).then(res => {
      let response = res.data.map((x: Bill) => {
        x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
        x.key = x.id;
        return x
      })
      setLoading(false);
      setdataGuias(response.reverse());
    });
  }

  return {
    loading,
    dataLabels,
    columnsLabels,
    rowSelection,
    selectedCompanyTransport,
    companyTransportList,
    selectedRowKeys,
    labelsList,
    ModalVisible,
    billsArrayList,
    setModalVisible,
    setSelectedCompanyTransport,
    getPendingBillsByStatus,
    getProductList,
    getCompanyTransports,
    getCities,
    printMode,
    printLabels,
    ModalDetailBillVisible,
    setModalDetailBillVisible,
    selectedGuia,
    getPendingBillsGuias
  };
};

export default useWarehouse;
